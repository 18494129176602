.center{
    display: flex;
    justify-content: center;

}
.helperText{
    font-size: large;
}
.center{
    display: flex;
    justify-content: center;

}
.uploadButton{
    width: 50%;
}
.widthClass{
    width: 50%;
}
.centerBothWays {
    margin-left: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}