.iframe{
   background: #FFFFFF;
    border: none;border-radius: 2px;
    box-shadow: 0 2px 10px 0 rgba(70, 76, 79, .2);
    width:640px;
    height:480px;
    margin: 5px;
}
.iframe-small{
    background: #FFFFFF;
    border: none;border-radius: 2px;
    box-shadow: 0 2px 10px 0 rgba(70, 76, 79, .2);
    width:80px;
    height:60px;
}