.rcorners3 {
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 15px 50px;
   /* border-color: #282c34;
    border-style: inset;*/
    padding: 20px;
    width: content-box;
    height: content-box;
}

.centerDiv {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 300px;

}

.buttonWidth {
    width: 300px;
}